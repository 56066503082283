import React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {Col, Container, Row} from "react-bootstrap";

const CookiesPage = () => {

    return (
        <Layout lang={'cy'} langURL={'/cy/cookies/'}>
            <Seo title="Welcome Teachers!" description="All the information you need to teach your class" lang={'en-GB'} />
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-5'}>
                            <h1>Cookies Policy</h1>
                            
                            <h2>Summary</h2>
                            <p>A cookie is a small text file that is stored on your computer or device when you visit a
                                particular website. This website uses cookies in order to:</p>
                            <ul>
                                <li>allow this website to work properly, and help keep it secure</li>
                                <li>make this website easier to use by remembering you</li>
                                <li>help us to understand how people use this website</li>
                            </ul>
                            <p>More information about how this website uses cookies is set out below.</p>
                            <h2>About this policy</h2>
                            <p>This policy explains what cookies are, why we use them and what we use them for, and
                                contains details of the cookies used on this website. We have also provided information
                                about how you can manage cookies.</p>
                            <p>We hope that, after reading this policy, you will allow us to continue to use cookies to
                                help maximise your experience when using this website.</p>
                            <h2>What is a cookie?</h2>
                            <p>Cookies are short strings of text that are sent from a web server to your web browser
                                memory when you visit a particular website. The cookie is stored on your computer but it
                                is not an executable program and cannot damage your hardware or software.</p>
                            <p>There are many different types of cookie, including:</p>
                            <p><strong>Session Cookies</strong> - These are temporary cookie files that are deleted when
                                you close your internet browser. This means these cookies will not be remembered on your
                                next visit.</p>
                            <p><strong>Persistent Cookies</strong> - These are cookies which stay in your browser until
                                you either choose to delete them or they expire. These cookies can have an expiry date
                                of up to 5 years.</p>
                            <h2>Why do we use cookies?</h2>
                            <p>Cookies help us to collect information from you, which we can use when you visit or use
                                our website again. In this way, we can determine where you have got to in some of our
                                online games.</p>
                            <p>Some cookies are needed so that this site works safely and securely.</p>
                            <p>On this website, we use cookies for the following reasons:</p>
                            <ul>
                                <li>To monitor how our customers use the website, which helps us make changes to improve
                                    your website experience.
                                </li>
                                <li>To make sure that your progress through our games is logged, so you don’t have to
                                    finish them all in one go.
                                </li>
                                <li>To collect demographic information and visitor statistics which enable us to improve
                                    the content and structure of our website.
                                </li>
                                <li>To collect data on viewed pages.</li>
                                <li>We also share information about your use of this site with our trusted social media,
                                    advertising and analytics partners.
                                </li>
                            </ul>
                            <p>All of the cookies we use collect information anonymously (so that we cannot identify
                                you). More details about how we use personal information about you is contained in our
                                <a href="https://www.dylansteachersquad.co.uk/en/privacy-notice/">privacy notice.</a></p>
                            <h2>What cookies do we use?</h2>
                            <p>We use the following cookies on our website:</p>
                            <p><strong>Strictly Necessary</strong> - These are cookies that are deemed necessary to the
                                operation of the website, such as those that measure and those that distribute website
                                traffic or those that allow you to log in to secure areas.</p>
                            <p><strong>Performance</strong> - These are cookies that track how well the site is working,
                                identify any issues with pages and how you are using our website.</p>
                                <div id={'manage-cookies2'} />
                            <p><strong>Functional</strong> - These are cookies that are used to provide services or to
                                remember settings in order to improve your experience on our website, such as language
                                preferences.</p>
                                <div id={'manage-cookies3'} />
                            <p>You can find a more detailed view of our cookies at the base of this page. You can manage
                                all cookies by amending the settings in your browser and can opt out of Analytics
                                cookies using the toggles above.</p>
                            <h2>Cookies used on this website</h2>
                            <script id='CookieDeclaration' src='https://consent.cookiebot.com/477a20a2-3536-44f4-8924-bb93ca22dec1/cd.js' type='text/javascript' async></script>
                            </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    )
}

export default CookiesPage;